import { FormHelperText, Select as MuiSelect } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { SelectProps } from '../../../models/ui';
import { getUniqueKey } from '../../helpers';
import theme from '../theme';

const Select = ({
	defaultValue = '',
	disabled,
	fullWidth,
	helperText = '',
	isStringValues = false,
	label,
	multiline,
	multiple,
	name,
	onChange,
	options,
	originObjectId,
	required,
	size = 'small',
	style,
	value = '',
	width = '200px',
}: SelectProps) => {
	const selectSize = fullWidth ? '100%' : width;

	const getOptionStyles = (name: string, values: string[]) => {
		return {
			fontWeight:
				values.indexOf(name) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium,
		};
	};

	return (
		<Box sx={{ minWidth: selectSize }}>
			<FormControl fullWidth size={size} required={required}>
				<InputLabel disabled={disabled} id="select-label">
					{label}
				</InputLabel>
				<MuiSelect
					style={style}
					disabled={disabled}
					defaultValue={defaultValue}
					id="select"
					label={label}
					labelId="select-label"
					onChange={(e) =>
						onChange(e, isStringValues ? undefined : options, originObjectId)
					}
					value={value !== undefined ? value : defaultValue}
					name={name ?? label}
					multiline={multiline}
					multiple={multiple}
					renderValue={isStringValues ? undefined : () => value?.name ?? value}
				>
					<MenuItem disabled key="NC" value={defaultValue}>
						Choisissez une option
					</MenuItem>
					{options?.map((option) => (
						<MenuItem
							disabled={option.disabled}
							key={getUniqueKey(option?.id ?? option)}
							value={option?.id ?? option}
							style={getOptionStyles(option, options)}
						>
							{option?.name ?? option ?? ''}
						</MenuItem>
					))}
				</MuiSelect>
				<FormHelperText error>{helperText}</FormHelperText>
			</FormControl>
		</Box>
	);
};

export default Select;
