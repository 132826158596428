/* eslint-disable no-mixed-spaces-and-tabs */
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import { ReactComponent as ArrowLeftRight } from '../../../assets/icons/navigator/arrow-left-right.svg';
import { ReactComponent as Close } from '../../../assets/icons/navigator/close.svg';
import { ReactComponent as DashbordLogo } from '../../../assets/icons/navigator/dashboard.svg';
import { ReactComponent as HomeLogo } from '../../../assets/icons/navigator/home.svg';
import { ReactComponent as SettingLogo } from '../../../assets/icons/navigator/setting.svg';
import { ReactComponent as UserLogo } from '../../../assets/icons/navigator/user.svg';
import { MenuItem } from '../../../models/ui';
import { getUniqueKey } from '../../../packages/helpers';
import {
	EDIT_USED_TRAIN,
	SHOW_DASHBOARD,
	SHOW_ESPACE3,
	SHOW_HABILITY,
	SHOW_OP,
	SHOW_RESOLVED_INCIDENT,
	SHOW_RS,
	SHOW_SETTINGS,
} from '../../../packages/helpers/rigths';
import {
	useEntity,
	useEspace,
	useMediaQuery,
	useRight,
} from '../../../packages/hooks';
import { useDepartment } from '../../../packages/hooks/useDepartment';
import { Button, Divider, Dropdown } from '../../../packages/ui';
import { ArchiveIcon, RailwayAlertIcon } from '../../../packages/ui/icons';
import { switchEspace } from '../../../redux/actions/espace.actions';
import { authLogout } from '../../../redux/thunks/auth';
import { Endpoints } from '../../../routes/endpoint.config';

import '../styles.css';

const Menu = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { can } = useRight();
	const { isMobile, isPad } = useMediaQuery();
	const { registrationNumber, isRp, isAdmin } = useEntity();
	const { isEspace3, isEspace5, ESPACE_3, ESPACE_5 } = useEspace();
	const {
		hasMtsDepartment,
		hasRerDepartment,
		hasSemDepartment,
		rsLabelPluralize,
	} = useDepartment();

	const handleLogout = () => {
		dispatch(authLogout());
	};

	const changeEspace = (espace: number) => {
		dispatch(switchEspace(espace));
		navigate(
			espace === ESPACE_3
				? Endpoints.TRACKING_BOARD_ST
				: Endpoints.TRACKING_BOARD_RS
		);
	};

	const displayDivider =
		(isEspace5 && (can(SHOW_ESPACE3) || hasSemDepartment)) ||
		(isEspace3 && (hasMtsDepartment || hasRerDepartment));

	const menuItems: MenuItem[] = [
		...(isEspace5
			? [
					{
						content: (
							<Link to={Endpoints.REPORTING}>
								<DashbordLogo
									id="svg-dashboard-logo"
									style={{ paddingRight: '10px' }}
								/>
								<span className="menu-items">Tableau de bord</span>
							</Link>
						),
						isValid: can(SHOW_DASHBOARD),
						key: getUniqueKey(SHOW_DASHBOARD),
					},
					{
						content: (
							<Link
								to={
									isEspace5
										? Endpoints.TRACKING_BOARD_RS
										: Endpoints.TRACKING_BOARD_ST
								}
							>
								<HomeLogo id="svg-home" style={{ paddingRight: '10px' }} />
								<span className="menu-items">Suivi des prestations</span>
							</Link>
						),
						isValid: can(SHOW_RS),
						key: getUniqueKey(SHOW_RS),
					},
					{
						content: (
							<Link to={Endpoints.USED_RS}>
								<RailwayAlertIcon
									fontSize="small"
									id="svg-user"
									style={{ color: 'gray', paddingRight: '3px' }}
								/>
								<span className="menu-items">{`${rsLabelPluralize} utilisés`}</span>
							</Link>
						),
						isValid: can(EDIT_USED_TRAIN),
						key: getUniqueKey('usedRs-panel'),
					},
					{
						content: (
							<Link to={Endpoints.OP}>
								<DashbordLogo
									id="svg-dashboard-logo"
									style={{ paddingRight: '10px' }}
								/>
								<span className="menu-items">Opérations spéciales</span>
							</Link>
						),
						isValid: can(SHOW_OP),
						key: getUniqueKey(SHOW_OP),
					},
					{
						content: (
							<Link to={Endpoints.SETTING}>
								<SettingLogo
									id="svg-setting-logo"
									style={{ paddingRight: '10px' }}
								/>
								<span className="menu-items">Paramètres</span>
							</Link>
						),
						isValid: can(SHOW_SETTINGS),
						key: getUniqueKey(SHOW_SETTINGS),
					},
			  ]
			: []),
		{
			content: (
				<Link to={Endpoints.HABILITATION}>
					<UserLogo
						id="svg-user"
						style={{ color: 'gray', paddingRight: '10px' }}
					/>
					<span className="menu-items">Habilitations</span>
				</Link>
			),
			isValid: can(SHOW_HABILITY),
			key: getUniqueKey(SHOW_HABILITY),
		},
		{
			content: (
				<Link to={Endpoints.TRACKING_BOARD_ST}>
					<UserLogo
						id="svg-user"
						style={{ color: 'gray', paddingRight: '10px' }}
					/>
					<span className="menu-items">Suivi de prestation</span>
				</Link>
			),
			isValid: isRp && hasSemDepartment,
			key: getUniqueKey(SHOW_HABILITY),
		},
		{
			content: (
				<Link to={Endpoints.RIGHT}>
					<UserLogo id="svg-right" style={{ paddingRight: '10px' }} />
					<span className="menu-items">Droits</span>
				</Link>
			),
			isValid: isAdmin && isEspace5,
			key: getUniqueKey('isAdmin'),
		},
		{
			content: (
				<Link to={Endpoints.SOLVEDINCIDENTREPORT}>
					<ArchiveIcon
						fontSize="small"
						id="svg-archive"
						style={{ color: 'gray', paddingRight: '3px' }}
					/>
					<span className="menu-items">Anomalies résolues</span>
				</Link>
			),
			isValid: can(SHOW_RESOLVED_INCIDENT),
			key: getUniqueKey('solvedIncidentReport'),
		},
		{
			content: <Divider sx={{ margin: '0px' }} />,
			isValid: displayDivider,
			key: 'd2',
		},
		{
			content: (
				<Button
					variant="text"
					onClick={() => changeEspace(ESPACE_3)}
					className="menu-items"
					label={`Espace ${ESPACE_3}`}
					startIcon={
						<ArrowLeftRight
							id="svg-arrow-left-right-3"
							style={{ color: 'gray', paddingRight: '5px' }}
						/>
					}
				/>
			),
			isValid: isEspace5 && (can(SHOW_ESPACE3) || hasSemDepartment),
			key: getUniqueKey('displayEspace3'),
		},
		{
			content: (
				<Button
					variant="text"
					onClick={() => changeEspace(ESPACE_5)}
					className="menu-items"
					label={`Espace ${ESPACE_5}`}
					startIcon={
						<ArrowLeftRight
							id="svg-arrow-left-right-3"
							style={{ color: 'gray', paddingRight: '5px' }}
						/>
					}
				/>
			),
			isValid: isEspace3 && (hasMtsDepartment || hasRerDepartment),
			key: getUniqueKey('displayEspace5'),
		},

		{
			content: <Divider sx={{ margin: 0 }} />,
			isValid: displayDivider,
			key: 'd1',
		},
		{
			content: (
				<Button
					variant="text"
					onClick={() => handleLogout()}
					className="menu-items"
					label="Déconnexion"
					startIcon={
						<Close
							id="svg-close"
							style={{ color: 'gray', paddingRight: '5px' }}
						/>
					}
				/>
			),
			key: getUniqueKey('logout'),
		},
	].filter(Boolean);

	return (
		<Dropdown
			buttonId="nav-dropdown"
			buttonStyle={{ color: 'white', fontSize: '15px' }}
			buttonTitle={
				isPad || isMobile ? <MenuIcon fontSize="large" /> : registrationNumber
			}
			items={menuItems}
		/>
	);
};

export default memo(Menu);
