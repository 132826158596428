import { Grid, Typography, useMediaQuery } from '@mui/material';

import { getUniqueKey } from '../../../../../packages/helpers';
import { useDepartment } from '../../../../../packages/hooks';
import { Badge } from '../../../../../packages/ui';
import IncidentSvgPrintable from '../../../../../packages/ui/icons/IncidentSvgPrintable';
import theme from '../../../../../packages/ui/theme';
import Logo from '../../../../navBar/logo';
import { StyledIncidentCard } from '../../../../trackingBoard/list/styles';

const HeaderIncidentDetails = ({
	incidentCode,
	count,
	trainCode,
	isMultisolving,
	isIncidentSolved,
	incidentReportId,
}: HeaderIncidentDetails) => {
	const { hasRerDepartment } = useDepartment();

	const trainLabel = `${hasRerDepartment ? "de l'élément :" : 'du train :'}`;
	const trainNumber = `Numéro ${trainLabel}`;
	const incidentNumber = "Numéro de l'anomalie :";

	const incidentPartialResolve = isMultisolving && incidentCode === 'GR';

	const isPad = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

	return (
		<Grid
			container
			className="incident-header"
			alignItems="center"
			zIndex={20}
			paddingLeft={isPad ? 2 : 0}
			paddingRight={isPad ? 2 : 0}
		>
			<Grid item xs={1}>
				<Logo size="small" />
			</Grid>
			<Grid
				item
				xs={10}
				className="incident-header-train-number"
				paddingLeft={1}
			>
				<Typography variant="h6">
					{isIncidentSolved ? incidentNumber : trainNumber}
				</Typography>
				<Typography variant="h5" paddingLeft={3}>
					{isIncidentSolved ? incidentReportId : trainCode}
				</Typography>
			</Grid>
			<Grid xs={1} item className="incident-svg">
				<Badge
					badgeContent={count >= 2 ? count : 0}
					key={getUniqueKey(incidentCode.toString())}
					color="error"
				>
					<StyledIncidentCard
						elevation={4}
						border={
							incidentPartialResolve
								? '3px solid var(--global-color-warning)'
								: 'none'
						}
					>
						<IncidentSvgPrintable name={incidentCode} />
					</StyledIncidentCard>
				</Badge>
			</Grid>
		</Grid>
	);
};

export default HeaderIncidentDetails;

type HeaderIncidentDetails = {
	incidentCode: string;
	count: number;
	trainCode?: string;
	isMultisolving?: boolean;
	isIncidentSolved: boolean;
	incidentReportId?: number;
};
