import { Grid } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';

import { IncidentTypeCodeMap, IncidentTypeEnum } from '../../../models';
import { IncidentReportSearchResult } from '../../../models/incidentReportSearch.model';
import { DataGridPro, Spinner } from '../../../packages/ui';
import { IncidentSvgPrintable } from '../../../packages/ui/icons';

const getSvgIconName = (incidentTypeId: IncidentTypeEnum): string => {
	const result = IncidentTypeCodeMap.get(incidentTypeId);
	return result !== undefined ? result : 'GR';
};

const columns: GridColDef[] = [
	{
		align: 'center',
		field: 'id',
		flex: 0.5,
		headerAlign: 'center',
		headerName: 'Identifiant',
		type: 'string',
		width: 200,
	},
	{
		align: 'center',
		field: 'incidentTypeId',
		flex: 0.5,
		headerAlign: 'center',
		headerName: "Type d'anomalie",
		renderCell: ({ row }) => (
			<IncidentSvgPrintable name={getSvgIconName(row.incidentTypeId)} />
		),
		width: 200,
	},
	{
		align: 'center',
		field: 'creationDate',
		flex: 0.5,
		headerAlign: 'center',
		headerName: 'Date de création',
		type: 'date',
		width: 200,
	},
	{
		align: 'center',
		field: 'solvingDate',
		flex: 0.5,
		headerAlign: 'center',
		headerName: 'Date de résolution',
		type: 'date',
		width: 200,
	},
	{
		align: 'center',
		field: 'trainCode',
		flex: 0.5,
		headerAlign: 'center',
		headerName: 'Matériel',
		type: 'string',
		width: 200,
	},
	{
		align: 'center',
		field: 'isSent',
		flex: 0.5,
		headerAlign: 'center',
		headerName: 'Envoyé à SUR',
		type: 'boolean',
		width: 200,
	},
];

const SolvedIncidentReportDataGrid = ({
	handleSelectIncident,
	incidentReports,
	isLoaded,
}: SolvedIncidentReportDataGridProps) => {
	const onRowSelect = (params: GridRowParams) => {
		handleSelectIncident(params.row.id as number);
	};

	return isLoaded ? (
		<Grid container item spacing={3} sx={{ margin: 'auto', width: '90%' }}>
			<DataGridPro
				columns={columns}
				onRowClick={onRowSelect}
				rows={incidentReports}
				sx={{
					'& .css-axafay-MuiDataGrid-virtualScroller': {
						height: '55vh',
					},
					'& .MuiDataGrid-row': {
						backgroundColor: '#ffffff',
						border: 'solid 1px lightGrey',
						borderRadius: '0.5rem',
						margin: '0 0 0.8rem',
						maxWidth: '99.5%',
					},
					border: 'none',
					maxHeight: '70vh',
				}}
			/>
		</Grid>
	) : (
		<Spinner />
	);
};

export default SolvedIncidentReportDataGrid;

type SolvedIncidentReportDataGridProps = {
	incidentReports: IncidentReportSearchResult[];
	handleSelectIncident: (id: number) => void;
	isLoaded: boolean;
};
