import { useDispatch } from 'react-redux';
import { Grid, useMediaQuery } from '@mui/material';

import { IncidentReport, IncidentType } from '../../../../../models';
import { Button, SingleButtonsBloc } from '../../../../../packages/ui';
import { WarningIcon } from '../../../../../packages/ui/icons';
import theme from '../../../../../packages/ui/theme';
import {
	deleteAndSetIncidentReport,
	updateAndSetIncidentReport,
} from '../../../../../redux/thunks/incidentReport';

const ActionComfirmation = ({
	incidentType,
	incidentNumber,
	incidentReport,
	isDeletingMode,
	isIncidentSolved,
	onCancel,
	onClose,
	trainCode,
	handleSearch,
}: PopInFooterProps) => {
	const dispatch = useDispatch();
	const { name: incidentTypeName } = incidentType;
	const { rollingStockId, id: incidentReportId } = incidentReport;

	const handleDelete = (incidentReportId: number | undefined) => {
		if (incidentReportId && rollingStockId) {
			dispatch(
				deleteAndSetIncidentReport(
					incidentReportId,
					rollingStockId,
					incidentTypeName,
					trainCode
				)
			);
			onCancel();
		}
	};

	const isPad = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

	const handleUpdate = async (incidentReport: IncidentReport) => {
		if (incidentType) {
			onCancel();
			await dispatch(
				updateAndSetIncidentReport(incidentReport, incidentType, trainCode)
			);
		}
		if (handleSearch) {
			handleSearch();
		}
	};

	return (
		<Grid container alignItems={'center'}>
			<Grid item xs={0} paddingLeft={2}>
				<WarningIcon sx={{ color: '#FF5A00' }} />
			</Grid>
			<Grid item xs={isPad ? 6.4 : 7.5} paddingLeft={1}>
				{isDeletingMode
					? 'Voulez-vous supprimer le signalement '
					: 'Voulez-vous modifier le signalement '}
				{incidentTypeName} {isIncidentSolved ? '' : incidentNumber}?
			</Grid>
			<Grid item paddingLeft={isPad || (isDeletingMode && !isPad) ? 3 : 12}>
				<SingleButtonsBloc
					style={{ paddingLeft: !isDeletingMode && isPad ? '20px' : '0' }}
				>
					<Button
						onClick={() => {
							onCancel();
						}}
						variant="text"
						style={{
							width: '8rem',
						}}
					>
						Annuler
					</Button>
					<Button
						onClick={() => {
							isDeletingMode
								? handleDelete(incidentReportId)
								: handleUpdate(incidentReport);
							onClose();
						}}
						style={{
							backgroundColor: isDeletingMode ? '#FF1400' : '#0A007D',
							borderColor: isDeletingMode ? '#FF1400' : '#0A007D',
							borderRadius: '8px',
							color: 'white',
						}}
					>
						{isDeletingMode ? 'Supprimer et fermer' : 'Confirmer '}
					</Button>
				</SingleButtonsBloc>
			</Grid>
		</Grid>
	);
};

export default ActionComfirmation;

type PopInFooterProps = {
	incidentType: IncidentType;
	incidentNumber?: number;
	isDeletingMode: boolean;
	isIncidentSolved: boolean;
	onCancel: () => void;
	onClose: () => void;
	handleSearch?: () => void;
	incidentReport: IncidentReport;
	trainCode?: string;
};
