import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { isNil } from 'lodash';

import { SpecialOperation } from '../../../models/specialOperation.model';
import { Rs } from '../../../models/usedRs.model';
import SpecialOperationProvider from '../../../packages/dataProviders/resources/specialOperation';
import {
	useDepartment,
	useLine,
	useTypedSelector,
} from '../../../packages/hooks';
import { useRsUsed } from '../../../packages/hooks/useRsUsed';
import { Button, DataGridPro, Spinner, Switch } from '../../../packages/ui';
import { DoneIcon } from '../../../packages/ui/icons';
import theme from '../../../packages/ui/theme';
import { setSelectedLineUser } from '../../../redux/thunks/line';
import { loadUsedRs } from '../../../redux/thunks/rollingStockUsed';

const ImpactedRollingStockByOp = ({
	rsList,
	setSpecialOperations,
	specialOperationType,
	specialOperations,
	setCountOperationSpecial,
	setRsFiltered,
}: ImpactedRollingStockByOpProps) => {
	const dispatch = useDispatch();
	const [firstLineOp, setFirstLineOp] = useState<string>('');
	const { rsLabelwithUndefinedArticle } = useDepartment();
	const { selectedLine, hasMultiLines } = useLine();
	const { usedRs } = useRsUsed();

	const isPad = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

	const isLoaded = useTypedSelector(
		({ apiState }) => apiState.rollingstock.isLoaded
	);

	const specialOperation = useTypedSelector(({ specialOperationTypeState }) =>
		specialOperationTypeState.opInProgress.find(
			(item) => item.name === specialOperationType
		)
	);

	const loadOperationSpecial = async () => {
		if (selectedLine && specialOperation) {
			const loadOp = await SpecialOperationProvider.loadOp(
				selectedLine,
				specialOperation.id
			);
			setSpecialOperations(loadOp.data);
		}
	};

	const onUsedStatusChange = async (
		idCb: number,
		creationDate: string,
		solvingDate: string,
		rollingStockId: number
	) => {
		if (
			idCb &&
			creationDate &&
			(solvingDate === null || solvingDate === undefined)
		) {
			const existingIndex = specialOperations.findIndex(
				(operation) => operation.id == idCb
			);
			setSpecialOperations((prevOperations) =>
				prevOperations.filter(
					(operation, index) => index !== existingIndex && operation
				)
			);
			SpecialOperationProvider.deleteOperationSpecial(idCb);
		}

		if (idCb && creationDate === undefined) {
			const newOperation: SpecialOperation = {
				creationDate: new Date(),
				line: selectedLine,
				rollingStockId: idCb,
				specialOperationTypeId: specialOperation?.id,
			};

			const createOp = await SpecialOperationProvider.createSpecialOperation(
				newOperation
			);

			if (createOp.data) {
				setSpecialOperations((prevOperations) => [
					...prevOperations,
					{ ...newOperation, id: createOp.data },
				]);
			}
		}

		if (idCb && creationDate && solvingDate) {
			const newOperation: SpecialOperation = {
				creationDate: new Date(),
				line: selectedLine,
				rollingStockId: rollingStockId,
				specialOperationTypeId: specialOperation?.id,
			};

			const createOp = await SpecialOperationProvider.createSpecialOperation(
				newOperation
			);

			if (createOp.data) {
				setSpecialOperations((prevOperations) => [
					...prevOperations,
					{ ...newOperation, id: createOp.data },
				]);
			}
		}
	};

	const newRsList = rsList.map((rs) => {
		const selectedOperations = specialOperations.filter(
			(op) => op.rollingStockId === rs.id
		);
		if (selectedOperations.length > 0) {
			const selectedUnresolvedOps = selectedOperations.filter(
				(op) => op.solvingDate === null || op.solvingDate === undefined
			);

			if (selectedUnresolvedOps.length > 0) {
				return {
					...rs,
					...selectedUnresolvedOps[0],
				};
			} else {
				return {
					...rs,
					...selectedOperations[0],
				};
			}
		}
		return rs;
	});

	const scrollToTop = () => {
		const virtualScroller = document.querySelector(
			'.css-axafay-MuiDataGrid-virtualScroller'
		);
		if (virtualScroller) {
			virtualScroller.scrollTo({ behavior: 'smooth', top: 0 });
		}
	};

	const columns: GridColDef[] = [
		{
			field: 'elementCode',
			flex: 0.4,
			headerName: `N° ${rsLabelwithUndefinedArticle}`,
			renderCell: ({ row: { trainCode } }) => (
				<Typography variant="h5">{trainCode}</Typography>
			),
			type: 'string',
			valueGetter: ({ row: { trainCode } }) => trainCode,
		},
		{
			align: 'center',
			field: 'opMade',
			flex: 0.5,
			headerAlign: 'center',
			headerName: 'Prestation réalisée',
			renderCell: (params) =>
				params.row.solvingDate ? (
					<DoneIcon
						style={{
							color: 'green',
						}}
					/>
				) : null,
		},
		{
			align: 'right',
			editable: true,
			field: 'opSend',
			flex: 0.5,
			headerAlign: 'right',
			headerName: 'Prestation demandée',
			renderCell: (params) => (
				<Switch
					checked={
						(params.row.creationDate && params.row.solvingDate === null) ||
						(params.row.creationDate && params.row.solvingDate === undefined)
					}
					isGreen
					handler={() =>
						onUsedStatusChange(
							params.row.id,
							params.row.creationDate,
							params.row.solvingDate,
							params.row.rollingStockId
						)
					}
					name="opSend"
				/>
			),
			type: 'boolean',
		},
	];

	useEffect(() => {
		if (selectedLine) {
			dispatch(loadUsedRs(selectedLine));
		}
	}, [selectedLine]);

	useEffect(() => {
		const countSpecialOperations = newRsList.filter(
			(item) =>
				(item.creationDate && isNil(item.solvingDate)) ||
				(item.creationDate && item.solvingDate === undefined)
		).length;
		setCountOperationSpecial(countSpecialOperations);
	}, [newRsList]);

	useEffect(() => {
		if (specialOperation) setFirstLineOp(specialOperation.lines[0]);
	}, [specialOperation]);

	useEffect(() => {
		setRsFiltered(usedRs);
		loadOperationSpecial();
	}, [usedRs, selectedLine]);

	useEffect(() => {
		if (firstLineOp && hasMultiLines)
			dispatch(setSelectedLineUser(firstLineOp));
	}, [firstLineOp]);

	if (!isLoaded) return <Spinner />;

	return (
		<Grid container spacing={2} justifyContent="center">
			{isPad ? (
				<>
					<Grid item xs={12}>
						<DataGridPro
							rows={newRsList}
							columns={columns}
							rowHeight={80}
							sx={{
								'& .css-axafay-MuiDataGrid-virtualScroller': {
									maxHeight: '68vh',
								},
								'& .MuiDataGrid-row': {
									backgroundColor: '#ffffff',
									border: 'solid 1px lightGrey',
									borderRadius: '0.5rem',
									margin: '0 0 0.8rem',
									maxWidth: '97.6%',
								},
								border: 'none',
							}}
						/>
					</Grid>

					<Grid item xs={8}>
						<Button fullWidth onClick={scrollToTop}>
							<ArrowUpwardIcon fontSize="small" />
							<span>Haut de page</span>
							<ArrowUpwardIcon fontSize="small" />
						</Button>
					</Grid>
				</>
			) : (
				<Grid item xs={12}>
					<DataGridPro
						rows={newRsList}
						columns={columns}
						rowHeight={60}
						sx={{
							'& .css-axafay-MuiDataGrid-virtualScroller': {
								height: '70vh',
							},
							'& .MuiDataGrid-row': {
								backgroundColor: '#ffffff',
								border: 'solid 1px lightGrey',
								borderRadius: '0.5rem',
								margin: '0 0 0.8rem',
								maxWidth: '99.5%',
								minWidth: '99%',
							},
							border: 'none',
							height: '70vh',
						}}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default ImpactedRollingStockByOp;

export type ImpactedRollingStockByOpProps = {
	setSpecialOperations: Dispatch<SetStateAction<SpecialOperation[]>>;
	rsList: Rs[];
	specialOperationType: string | null;
	specialOperations: SpecialOperation[];
	setCountOperationSpecial: Dispatch<SetStateAction<number>>;
	setRsFiltered: Dispatch<SetStateAction<any>>;
};
