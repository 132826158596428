import { isNil } from 'lodash';

import { IncidentReportSearchQuery } from '../../models/incidentReportSearch.model';

import { RollingstockQuery } from './rollingStockService';
import { ServiceQuery, TotalServiceQuery } from './servicesService';

export const getSearchParams = (
	queryOptions?:
		| ServiceQuery
		| RollingstockQuery
		| TotalServiceQuery
		| IncidentReportSearchQuery
): string => {
	if (!queryOptions) return '';
	const urlSearchParams = new URLSearchParams();
	Object.entries(queryOptions).forEach(([key, val]) => {
		if (!isNil(val)) return urlSearchParams.append(key, val as string);
	});
	return `/?${urlSearchParams.toString()}`;
};
