/* eslint-disable no-mixed-spaces-and-tabs */
import { ChangeEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { isEmpty, isNil, isNull } from 'lodash';

import { IncidentReportSearch } from '../../../models/incidentReportSearch.model';
import { dateTimeService } from '../../../packages/helpers';
import {
	INC_SOLVED_ID_LABEL,
	INC_SOLVED_TRAIN_CODE_LABEL,
	INC_SOLVED_TYPE_LABEL,
} from '../../../packages/helpers';
import {
	useDepartment,
	useLine,
	useTypedSelector,
} from '../../../packages/hooks';
import {
	DatePicker,
	Input,
	Select,
	ValidationButtons,
} from '../../../packages/ui';
import { loadAndSetRollingStock } from '../../../redux/thunks/rollingStock';

const SolvedIncidentReportSearch = ({
	handleChange,
	handleDateChange,
	handleSubmit,
	handleSelect,
	isUntouched,
	reset,
	values,
}: SolvedIncidentReportSearchProps) => {
	const { selectedLine } = useLine();
	const { departmentEnum } = useDepartment();
	const { npHighLimit } = useTypedSelector((state) => state.settingState);
	const { rollingStocks } = useTypedSelector(
		(state) => state.rollingStockState
	);
	const dispatch = useDispatch();
	const incidentTypesCreate = [
		{ code: 'GR', id: 0, name: 'Graffiti extérieur' },
		{ code: 'DI', id: 1, name: 'Signalement intérieur' },
		{ code: 'PB', id: 2, name: 'Pare-brise sale' },
	];

	const rollingStockOptions = rollingStocks.map((rollingStock) => {
		return { id: rollingStock.id, name: rollingStock.trainCode };
	});
	const sortedRollingStockOptions = rollingStockOptions.sort((a, b) =>
		a.name > b.name ? 1 : b.name > a.name ? -1 : 0
	);

	const isFillFieldsNeeded = (): boolean => {
		if (!isEmpty(values.id)) return false;
		if (!isEmpty(values.incidentType))
			return !(
				!isNull(values.creationStartDate) ||
				!isNull(values.creationEndDate) ||
				!isNull(values.solvingStartDate) ||
				!isNull(values.solvingEndDate)
			);
		return (
			!isEmpty(values.rollingstock) &&
			!(
				!isNull(values.creationStartDate) ||
				!isNull(values.creationEndDate) ||
				!isNull(values.solvingStartDate) ||
				!isNull(values.solvingEndDate)
			)
		);
	};

	const disabledButton = () =>
		(isEmpty(values.id) &&
			isEmpty(values.incidentType) &&
			isEmpty(values.rollingstock)) ||
		isUntouched ||
		isFillFieldsNeeded();

	useEffect(() => {
		if (
			isEmpty(rollingStocks) &&
			!isEmpty(selectedLine) &&
			!isNil(npHighLimit)
		) {
			dispatch(
				loadAndSetRollingStock(npHighLimit, selectedLine, departmentEnum)
			);
		}
	}, [rollingStocks, selectedLine, npHighLimit]);
	return (
		<Grid
			container
			spacing={1}
			sx={{
				backgroundColor: 'white',
				borderRadius: '4px',
				boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
			}}
		>
			<Grid container item spacing={3} sx={{ margin: 'auto', width: '90%' }}>
				<Grid item xs={3}>
					<Typography variant="h6" mb={1}>
						Recherche par identifiant
					</Typography>
					<Input
						fullWidth
						type="number"
						name="id"
						onChange={handleChange}
						label={INC_SOLVED_ID_LABEL}
						value={values.id}
					/>
				</Grid>
				<Grid item xs={3}>
					<Typography variant="h6" mb={1}>
						Recherche par type d'anomalie
					</Typography>
					<Select
						disabled={!isEmpty(values.id)}
						name="incidentType"
						label={INC_SOLVED_TYPE_LABEL}
						onChange={handleSelect}
						options={incidentTypesCreate}
						value={values.incidentType}
					/>
				</Grid>
				<Grid item xs={3}>
					<Typography variant="h6" mb={1}>
						Recherche par numéro de matériel
					</Typography>
					<Select
						disabled={!isEmpty(values.id)}
						name="rollingstock"
						label={INC_SOLVED_TRAIN_CODE_LABEL}
						onChange={handleSelect}
						options={sortedRollingStockOptions}
						value={values.rollingstock}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				item
				spacing={3}
				my={1}
				sx={{ margin: 'auto', width: '90%' }}
			>
				<Grid item xs={6}>
					<Typography variant="h6">Date de création</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6">Date de résolution</Typography>
				</Grid>
			</Grid>
			<Grid container item spacing={3} sx={{ margin: 'auto', width: '90%' }}>
				<Grid item xs={3}>
					<DatePicker
						disabled={!isEmpty(values.id)}
						label="Du"
						minDate={
							values.creationEndDate
								? dateTimeService.addOrSubtractMonthsToDate(
										values.creationEndDate,
										3
								  )
								: undefined
						}
						maxDate={
							values.creationEndDate ? values.creationEndDate : undefined
						}
						fullWidth
						onChange={(value) => handleDateChange('creationStartDate', value)}
						value={values.creationStartDate}
					/>
				</Grid>
				<Grid item xs={3}>
					<DatePicker
						disabled={!isEmpty(values.id)}
						label="Au"
						minDate={
							values.creationStartDate ? values.creationStartDate : undefined
						}
						maxDate={
							values.creationStartDate
								? dateTimeService.addOrSubtractMonthsToDate(
										values.creationStartDate,
										3,
										'addition'
								  )
								: undefined
						}
						fullWidth
						onChange={(value) => handleDateChange('creationEndDate', value)}
						value={values.creationEndDate}
					/>
				</Grid>
				<Grid item xs={3}>
					<DatePicker
						disabled={!isEmpty(values.id)}
						label="Du"
						fullWidth
						minDate={
							values.solvingEndDate
								? dateTimeService.addOrSubtractMonthsToDate(
										values.solvingEndDate,
										3
								  )
								: undefined
						}
						maxDate={values.solvingEndDate ? values.solvingEndDate : undefined}
						onChange={(value) => handleDateChange('solvingStartDate', value)}
						value={values.solvingStartDate}
					/>
				</Grid>
				<Grid item xs={3}>
					<DatePicker
						disabled={!isEmpty(values.id)}
						label="Au"
						fullWidth
						minDate={
							values.solvingStartDate ? values.solvingStartDate : undefined
						}
						maxDate={
							values.solvingStartDate
								? dateTimeService.addOrSubtractMonthsToDate(
										values.solvingStartDate,
										3,
										'addition'
								  )
								: undefined
						}
						onChange={(value) => handleDateChange('solvingEndDate', value)}
						value={values.solvingEndDate}
					/>
				</Grid>
			</Grid>
			<Grid container item sx={{ margin: '1rem auto', width: '90%' }}>
				<Grid item xs={6}>
					{isFillFieldsNeeded() && (
						<Typography variant="body1">
							Veuillez saisir une date de création et/ou de résolution pour
							compléter votre recherche.
						</Typography>
					)}
				</Grid>
				<Grid item xs={6}>
					<ValidationButtons
						cancelStartIcon={<RestartAltIcon />}
						cancelText="Réinitialiser"
						cancelVariant="outlined"
						onCancel={reset}
						onValidate={handleSubmit}
						validateDisabled={disabledButton()}
						validateStartIcon={<SearchIcon />}
						validateText="Rechercher"
						validateVariant="contained"
						width="8 rem"
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

interface SolvedIncidentReportSearchProps {
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
	handleDateChange: any;
	handleSubmit: () => void;
	handleSelect: (event: SelectChangeEvent) => void;
	isUntouched: boolean;
	reset: () => void;
	values: IncidentReportSearch;
}

export default SolvedIncidentReportSearch;
