import { DepartmentEnum, RoleEnum } from '../models';

export enum Endpoints {
	// Public
	LOGIN = '/',
	LOGOUT = '/logout',

	// Prestataire MTS RER SEM
	AGENTN = '/prestataire',
	AGENTN_SELECTION = '/prestataire/selection',
	AGENTN_QR_SCANNER = '/prestataire/scanner',
	AGENTN_BY_CODE = '/prestataire/code',
	AGENTN_CONFIRM = '/prestataire/confirmation',
	AGENTN_SUCCESS = '/prestataire/success',

	// Reponsable MTS RER SEM
	HABILITATION = '/habilitations',
	SOLVEDINCIDENTREPORT = '/solved-incident-report',
	OP = '/special-operation',
	OPRS = '/special-operation-rs',
	REPORTING = '/dashboard',
	SETTING = '/setting',
	RIGHT = '/right',
	USED_RS = '/used-rolling-stock',
	// Reponsable MTS RER
	TRACKING_BOARD_RS = '/rolling-stock',
	// Reponsable SEM
	SEMSETTING = '/semsetting',
	TRACKING_BOARD_ST = '/station',
}

export const routesConf = {
	[Endpoints.LOGOUT]: {
		authorized: [RoleEnum.AGENTN],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
	[Endpoints.AGENTN]: {
		authorized: [RoleEnum.AGENTN],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
	[Endpoints.AGENTN_SELECTION]: {
		authorized: [RoleEnum.AGENTN],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
		explanation: 'Choix de la prestation',
	},
	[Endpoints.AGENTN_QR_SCANNER]: {
		authorized: [RoleEnum.AGENTN],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
		explanation: 'Scanner le code',
	},
	[Endpoints.AGENTN_BY_CODE]: {
		authorized: [RoleEnum.AGENTN],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
	[Endpoints.AGENTN_CONFIRM]: {
		authorized: [RoleEnum.AGENTN],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
		explanation: 'Validation',
	},
	[Endpoints.AGENTN_SUCCESS]: {
		authorized: [RoleEnum.AGENTN],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
	[Endpoints.SOLVEDINCIDENTREPORT]: {
		authorized: [
			RoleEnum.ADMIN,
			RoleEnum.AGENTM,
			RoleEnum.PROPRETE,
			RoleEnum.RESPROPRETE,
			RoleEnum.PROPRETESNCF,
			RoleEnum.TR,
			RoleEnum.TRMAL,
		],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
	[Endpoints.OP]: {
		authorized: [
			RoleEnum.ADMIN,
			RoleEnum.PROPRETE,
			RoleEnum.RESPROPRETE,
			RoleEnum.TR,
			RoleEnum.TRMAL,
			RoleEnum.AGENTM,
			RoleEnum.PROPRETESNCF,
		],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
	[Endpoints.OPRS]: {
		authorized: [
			RoleEnum.ADMIN,
			RoleEnum.PROPRETE,
			RoleEnum.RESPROPRETE,
			RoleEnum.TR,
			RoleEnum.TRMAL,
			RoleEnum.AGENTM,
			RoleEnum.PROPRETESNCF,
		],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
	[Endpoints.TRACKING_BOARD_RS]: {
		authorized: [
			RoleEnum.ADMIN,
			RoleEnum.AGENTM,
			RoleEnum.PROPRETE,
			RoleEnum.RESPROPRETE,
			RoleEnum.PROPRETESNCF,
			RoleEnum.TR,
			RoleEnum.TRMAL,
		],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
	[Endpoints.TRACKING_BOARD_ST]: {
		authorized: [
			RoleEnum.ADMIN,
			RoleEnum.AGENTM,
			RoleEnum.PROPRETE,
			RoleEnum.RESPROPRETE,
			RoleEnum.PROPRETESNCF,
			RoleEnum.TR,
			RoleEnum.TRMAL,
		],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
	[Endpoints.HABILITATION]: {
		authorized: [
			RoleEnum.ADMIN,
			RoleEnum.PROPRETE,
			RoleEnum.RESPROPRETE,
			RoleEnum.PROPRETESNCF,
			RoleEnum.TR,
		],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
	[Endpoints.REPORTING]: {
		authorized: [
			RoleEnum.ADMIN,
			RoleEnum.PROPRETE,
			RoleEnum.RESPROPRETE,
			RoleEnum.TR,
			RoleEnum.TRMAL,
			RoleEnum.AGENTM,
			RoleEnum.PROPRETESNCF,
		],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
	[Endpoints.SETTING]: {
		authorized: [
			RoleEnum.ADMIN,
			RoleEnum.PROPRETE,
			RoleEnum.RESPROPRETE,
			RoleEnum.PROPRETESNCF,
		],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER],
	},

	[Endpoints.SEMSETTING]: {
		authorized: [RoleEnum.ADMIN, RoleEnum.PROPRETE, RoleEnum.RESPROPRETE],
		departments: [DepartmentEnum.SEM],
	},
	[Endpoints.RIGHT]: {
		authorized: [RoleEnum.ADMIN],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
	[Endpoints.USED_RS]: {
		authorized: [
			RoleEnum.ADMIN,
			RoleEnum.AGENTM,
			RoleEnum.PROPRETE,
			RoleEnum.RESPROPRETE,
			RoleEnum.PROPRETESNCF,
			RoleEnum.TR,
			RoleEnum.TRMAL,
		],
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER, DepartmentEnum.SEM],
	},
};
