import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, useMediaQuery } from '@mui/material';
import { isNil } from 'lodash';

import {
	IncidentReport,
	IncidentType,
	IncidentTypeCodeMap,
	IncidentTypeEnum,
	IncidentTypeMap,
} from '../../../models';
import { INC_SOLVED_DETAIL_TITLE } from '../../../packages/helpers';
import {
	ModalesState,
	useForm,
	useLine,
	useTypedSelector,
} from '../../../packages/hooks';
import { Dialog } from '../../../packages/ui';
import PopInFooter from '../../../packages/ui/molecules/CloseButton';
import theme from '../../../packages/ui/theme';
import { updateAndSetIncidentReport } from '../../../redux/thunks/incidentReport';
import { getEmptyIncidentReport, validate } from '../../incidentReport/config';
import PopInActionsFooter from '../incidentReport/IncidentReportEditForm/footer/ActionConfirmation';
import FormContentDetails from '../incidentReport/IncidentReportEditForm/formDetails';
import HeaderIncidentDetails from '../incidentReport/IncidentReportEditForm/header';

const SolvedIncidentReportDetails = ({
	open,
	handleCloseModal,
	solvedIncidentDetail,
	handleSearch,
}: SolvedIncidentReportDetailsProps) => {
	const dispatch = useDispatch();
	const { selectedLine } = useLine();
	const isPad = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
	const [isDeletingMode, setIsDeletingMode] = useState(false);
	const [incidentNumber, setIncidentNumber] = useState<number>();
	const [selectedIncidentReportId, setSelectedIncidentReportId] = useState<
		number | null
	>(null);

	const {
		id: incidentReportId,
		incidentTypeId,
		rollingStockId,
		rollingStockCode,
	} = solvedIncidentDetail;

	const isIncidentSolved = true;

	const rollingStockList = useTypedSelector(
		({ rollingStockState }) => rollingStockState.rollingStocks
	);

	const trainCode = rollingStockList.find(
		(t) => t.id === rollingStockId
	)?.trainCode;

	const getIncidentTypeCode = (incidentTypeId: IncidentTypeEnum): string => {
		const result = IncidentTypeCodeMap.get(incidentTypeId);
		return result !== undefined ? result : 'GR';
	};

	const getIncidentTypeName = (incidentTypeId: IncidentTypeEnum): string => {
		const result = IncidentTypeMap.get(incidentTypeId);
		return result !== undefined ? result : 'GR';
	};

	const incidentType: IncidentType = {
		code: getIncidentTypeCode(incidentTypeId),
		id: incidentTypeId,
		isMultisolving: false,
		name: getIncidentTypeName(incidentTypeId),
	};

	const handleCancel = () => {
		handleResetFiles();
		setSelectedIncidentReportId(null);
		setIsDeletingMode(false);
	};

	const handleClose = () => {
		handleCancel();
		handleCloseModal('solvedIncidentReportEditForm');
	};

	const handleUpdateIncident = () => {
		if (incidentTypeId) {
			dispatch(
				updateAndSetIncidentReport(
					values as IncidentReport,
					incidentType,
					rollingStockCode
				)
			);
		}
		handleCancel();
		handleCloseModal('solvedIncidentReportEditForm');
	};

	const {
		errors,
		files,
		handleChange,
		handleCheck,
		handleCheckOneOf,
		handleCheckChange,
		handleDateChange,
		handleDeleteFile,
		handleElementSelectOneOf,
		handleFiles,
		handleResetFiles,
		handleSelect,
		setValues,
		values,
	} = useForm({
		callback: handleUpdateIncident,
		initFormValues: getEmptyIncidentReport(incidentTypeId, selectedLine),
		validate,
	});

	return (
		<Dialog
			open={open}
			titleContent={INC_SOLVED_DETAIL_TITLE}
			content={
				<Grid container className="incidentDetails">
					<Grid
						item
						xs={12}
						width={isPad ? 700 : 990}
						height={94}
						zIndex={10}
						boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.1)'}
						style={{
							backgroundColor: 'white',
							paddingTop: 5,
							position: 'sticky',
							top: 0,
						}}
					>
						<HeaderIncidentDetails
							trainCode={trainCode}
							incidentCode={incidentType.code}
							count={1} // count
							isMultisolving={false} // isMultisolving
							isIncidentSolved={isIncidentSolved}
							incidentReportId={incidentReportId}
						/>
					</Grid>

					<Grid item xs={12} paddingTop={3} paddingBottom={3}>
						<FormContentDetails
							isIncidentSolved={isIncidentSolved}
							errors={errors}
							incidentReport={solvedIncidentDetail}
							incidentType={incidentType}
							incidentIndex={0 + 1}
							setIsDeletingMode={setIsDeletingMode}
							setSelectedIncidentReportId={setSelectedIncidentReportId}
							isDeletingMode={isDeletingMode}
							selectedIncidentReportId={selectedIncidentReportId}
							setIncidentNumber={setIncidentNumber}
							handleChange={handleChange}
							handleCheck={handleCheck}
							handleCheckOneOf={handleCheckOneOf}
							handleCheckChange={handleCheckChange}
							handleDateChange={handleDateChange}
							handleElementSelectOneOf={handleElementSelectOneOf}
							handleSelect={handleSelect}
							setValues={setValues}
							values={values}
							files={files}
							handleFiles={handleFiles}
							handleDeleteFile={handleDeleteFile}
						/>
					</Grid>
				</Grid>
			}
			actionsContent={
				!isNil(selectedIncidentReportId) && incidentType ? (
					<PopInActionsFooter
						incidentType={incidentType}
						incidentReport={values}
						isDeletingMode={isDeletingMode}
						isIncidentSolved={isIncidentSolved}
						onCancel={handleCancel}
						onClose={handleClose}
						incidentNumber={incidentNumber}
						trainCode={trainCode}
						handleSearch={handleSearch}
					/>
				) : (
					<PopInFooter onCancel={handleClose} />
				)
			}
			onClose={handleClose}
		/>
	);
};

export default SolvedIncidentReportDetails;

type SolvedIncidentReportDetailsProps = {
	open: boolean;
	handleCloseModal: (modalName?: keyof ModalesState) => void;
	solvedIncidentDetail: IncidentReport;
	handleSearch: () => void;
};
