import { useState } from 'react';
import { Grid, Typography } from '@mui/material';

import { IncidentReport } from '../../models';
import { IncidentReportSearchResult } from '../../models/incidentReportSearch.model';
import IncidentReportProvider from '../../packages/dataProviders/resources/incidentReport';
import { INC_SOLVED_NOT_FOUND } from '../../packages/helpers';
import { useForm, useLine, useModal } from '../../packages/hooks';
import IncidentSolvedDetails from '../modals/solvedIncidentReportViewAndEditForm';

import {
	dtoToSolvedIncidentReport,
	getEmptyIncidentReportSearch,
	getIncidentReportSearchQuery,
} from './config';
import SolvedIncidentReportDataGrid from './list';
import SolvedIncidentReportSearch from './searchPart';

const SolvedIncidentReport = () => {
	const [solvedIncidentReports, setSolvedIncidentReports] = useState<
		IncidentReportSearchResult[] | undefined
	>(undefined);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [solvedIncidentDetail, setSolvedIncidentDetail] =
		useState<IncidentReport>();
	const { handleCloseModal, handleOpenModal, openModalState } = useModal();
	const { selectedLine } = useLine();
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const handleSearch = () => {
		setSolvedIncidentReports(undefined);
		const incidentReportSearchValues = getIncidentReportSearchQuery(
			values,
			selectedLine
		);
		IncidentReportProvider.loadSolvedIncidentReports(
			incidentReportSearchValues
		).then(({ data }) => {
			const newSolvedIncidentReports = data.map((incidentReport) =>
				dtoToSolvedIncidentReport(incidentReport)
			);
			setSolvedIncidentReports(newSolvedIncidentReports);
			setIsLoaded(true);
		});
		setValues({
			...values,
			creationEndDate: incidentReportSearchValues.creationEndDate,
			creationStartDate: incidentReportSearchValues.creationStartDate,
			solvingEndDate: incidentReportSearchValues.solvingEndDate,
			solvingStartDate: incidentReportSearchValues.solvingStartDate,
		});
		setIsSubmitted(true);
		setIsUntouched(true);
	};

	const handleSelectIncident = (incidentId: number) => {
		IncidentReportProvider.loadIncidentReportDetails(incidentId).then(
			({ data }) => {
				setSolvedIncidentDetail(data);
				handleOpenModal('solvedIncidentReportEditForm');
			}
		);
	};

	const reset = () => {
		setValues({ ...getEmptyIncidentReportSearch(), id: '' });
		setIsUntouched(true);
		setIsSubmitted(false);
	};

	const {
		handleChange,
		handleDateChange,
		handleSelect,
		isUntouched,
		setIsUntouched,
		setValues,
		values,
	} = useForm({
		callback: handleSearch,
		initFormValues: getEmptyIncidentReportSearch,
	});
	return (
		<>
			<SolvedIncidentReportSearch
				handleChange={handleChange}
				handleDateChange={handleDateChange}
				handleSelect={handleSelect}
				handleSubmit={handleSearch}
				isUntouched={isUntouched}
				reset={reset}
				values={values}
			/>
			{isSubmitted &&
				solvedIncidentReports &&
				(solvedIncidentReports.length > 0 ? (
					<SolvedIncidentReportDataGrid
						incidentReports={solvedIncidentReports}
						isLoaded={isLoaded}
						handleSelectIncident={handleSelectIncident}
					/>
				) : (
					<Grid item xs={12} textAlign="center" marginTop={6}>
						<Typography variant="body2">{INC_SOLVED_NOT_FOUND}</Typography>
					</Grid>
				))}
			{solvedIncidentDetail && (
				<IncidentSolvedDetails
					open={openModalState.solvedIncidentReportEditForm}
					handleCloseModal={handleCloseModal}
					solvedIncidentDetail={solvedIncidentDetail}
					handleSearch={handleSearch}
				/>
			)}
		</>
	);
};

export default SolvedIncidentReport;
