import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

import { IncidentTypeEnum } from '../../../../models';
import {
	DegradationPosition,
	IncidentComplement,
	IncidentRailcarDTO,
	IncidentReport,
	IncidentSite,
	Registrant,
} from '../../../../models/incidentReport.model';
import IncidentReportProvider from '../../../../packages/dataProviders/resources/incidentReport';
import { useDepartment, useLine } from '../../../../packages/hooks';

import IncidentReportFormContentComponent from './component';

const IncidentReportFormContent = ({
	errors,
	handleChange,
	handleCheck,
	handleCheckOneOf,
	handleCheckChange,
	handleDateChange,
	handleElementSelectOneOf,
	handleSelect,
	incidentReport,
	files,
	handleFiles,
	isEditableForm = true,
	isIncidentDetails = false,
	isIncidentSolved,
	handleDeleteFile,
	setValues,
}: IncidentReportFormContentProps) => {
	const { incidentTypeId, degradationSite } = incidentReport;
	const { hasRerDepartment } = useDepartment();
	const { selectedLine } = useLine();
	const [registrantOptions, setRegistrantOptions] = useState<Registrant[]>([]);
	const [siteOptions, setSiteOptions] = useState<IncidentSite[]>([]);
	const [complements, setComplements] = useState<IncidentComplement[]>([]);
	const [degradationSiteOptions, setDegradationSiteOptions] = useState<
		IncidentSite[]
	>([]);
	const [degradationPositionOptions, setDegradationPositionOptions] = useState<
		DegradationPosition[]
	>([]);

	useEffect(() => {
		if (selectedLine) {
			IncidentReportProvider.loadIncidentSites(incidentReport.lineId).then(
				({ data: sites }) => setSiteOptions(sites)
			);

			IncidentReportProvider.loadIncidentRegistrants().then(
				({ data: registrants }) => setRegistrantOptions(registrants)
			);

			if (incidentTypeId === IncidentTypeEnum.GR)
				IncidentReportProvider.loadIncidentDegradationSites(
					incidentReport.lineId
				).then(({ data: degradationSites }) =>
					setDegradationSiteOptions(degradationSites)
				);

			if (incidentTypeId === IncidentTypeEnum.DI)
				IncidentReportProvider.loadIncidentComplements(
					incidentReport.incidentTypeId
				).then(({ data: complements }) => setComplements(complements));
		}
	}, [selectedLine, incidentTypeId]);

	useEffect(() => {
		if (incidentTypeId === IncidentTypeEnum.GR)
			setValues({ ...incidentReport, degradationPosition: undefined });

		if (degradationSite)
			IncidentReportProvider.loadIncidentDegradationPositions(
				degradationSite.id
			).then(({ data: positions }) => setDegradationPositionOptions(positions));
	}, [degradationSite]);

	return (
		<IncidentReportFormContentComponent
			complementOptions={complements}
			degradationSiteOptions={degradationSiteOptions}
			errors={errors}
			files={files}
			handleChange={handleChange}
			handleCheck={handleCheck}
			handleCheckChange={handleCheckChange}
			handleCheckOneOf={handleCheckOneOf}
			handleDateChange={handleDateChange}
			handleDeleteFile={handleDeleteFile}
			handleElementSelectOneOf={handleElementSelectOneOf}
			handleFiles={handleFiles}
			handleSelect={handleSelect}
			incidentReport={incidentReport}
			isEditableForm={isEditableForm}
			isIncidentDetails={isIncidentDetails}
			isIncidentSolved={isIncidentSolved}
			isRer={hasRerDepartment}
			degradationPositionOptions={degradationPositionOptions}
			registrantOptions={registrantOptions}
			siteOptions={siteOptions}
		/>
	);
};

export default IncidentReportFormContent;

type IncidentReportFormContentProps = {
	errors: { [key: string]: string };
	setValues: Dispatch<SetStateAction<IncidentReport>>;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleCheckOneOf: (
		event: React.ChangeEvent<HTMLInputElement>,
		of: string
	) => void;
	handleCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleDateChange: any;
	handleElementSelectOneOf: (element: IncidentRailcarDTO, of: string) => void;
	handleSelect: (event: SelectChangeEvent) => void;
	incidentReport: IncidentReport;
	files?: any;
	handleFiles?: any;
	handleDeleteFile: (event: any, filename: string, isFile?: boolean) => void;
	isIncidentDetails?: boolean;
	isIncidentSolved: boolean;
	isEditableForm?: boolean;
};
