// Incident-report form
export const INC_FORM_ERR_SITE = 'Veuillez renseigner un site.';
export const INC_FORM_ERR_REGISTRANT =
	"Veuillez renseigner la fonction de l'agent découvreur.";
export const INC_FORM_ERR_COMPLEMENT =
	'Veuillez renseigner la nature du signalement.';
export const INC_FORM_ERR_COMMENT =
	'Vous ne pouvez pas écrire plus de 250 charactères.';
export const INC_FORM_ERR_LOCATIONS =
	'Veuillez renseigner au moins une localisation.';
export const INC_FORM_ERR_RAILCARS =
	'Veuillez renseigner au moins un pare-brise.';
export const INC_FORM_ERR_IMPACTED_RAILCAR =
	'Veuillez renseigner un nombre de véhicule concerné.';
export const INC_IS_BLOCKING_CASE = "Train incompatible à l'exploitation";
export const INC_IS_OFFENSIVE = 'Prise en charge prioritaire';

// Incident-report select ano form
export const INC_SEL_TITLE = 'Signaler une anomalie';
export const INC_DETAIL_TITLE = "Détails de l'anomalie";
export const INC_SEL_SUBTITLE = "Sélectionner l'anomalie :";
export const INC_RESOLVE_TITLE = 'Résoudre une anomalie ';

//Incident Report Solved
export const INC_SOLVED_TITLE = "Recherche d'anomalie(s) résolue(s)";
export const INC_SOLVED_ID_LABEL = "Identifiant de l'anomalie";
export const INC_SOLVED_TYPE_LABEL = "Type d'anomalie";
export const INC_SOLVED_TRAIN_CODE_LABEL = 'N° de matériel utilisé';
export const INC_IS_NOT_BLOCKING_CASE = "Train compatible à l'exploitation";
export const INC_IS_NOT_OFFENSIVE = 'Prise en charge non prioritaire';
export const INC_RAILCARS = 'Nombre de voitures dégradées';
export const INC_IMPACTED_RAILCARS = 'Nombre de voitures concernées';
export const INC_SOLVED_NOT_FOUND = "Pas d'anomalie résolue trouvée.";
export const INC_SOLVED_DETAIL_TITLE = "Détails de l'anomalie résolue";
