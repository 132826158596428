import { ReactNode } from 'react';

import { Button, SingleButtonsBloc } from '..';

const ValidationButtons = ({
	onValidate,
	onCancel,
	cancelStartIcon,
	cancelText = 'Annuler',
	cancelVariant = 'text',
	validateDisabled = false,
	validateStartIcon,
	validateText = 'Valider',
	validateVariant,
	width = '7rem',
}: PopInFooterProps) => {
	return (
		<SingleButtonsBloc>
			<Button
				onClick={() => {
					onCancel();
				}}
				variant={cancelVariant}
				startIcon={cancelStartIcon}
				style={{
					width,
				}}
			>
				{cancelText}
			</Button>
			<Button
				disabled={validateDisabled}
				onClick={() => {
					onValidate();
				}}
				startIcon={validateStartIcon}
				style={{
					width,
				}}
				variant={validateVariant}
			>
				{validateText}
			</Button>
		</SingleButtonsBloc>
	);
};

export default ValidationButtons;

type PopInFooterProps = {
	onValidate: () => void;
	onCancel: () => void;
	cancelStartIcon?: ReactNode;
	cancelText?: string;
	cancelVariant?: 'text' | 'contained' | 'outlined' | undefined;
	validateDisabled?: boolean;
	validateStartIcon?: ReactNode;
	validateText?: string;
	validateVariant?: 'text' | 'contained' | 'outlined' | undefined;
	width?: string;
};
