import { Item } from './item.model';
import { MultipleAvailabilityTypeEnum } from './multipleAvailability';

export interface IncidentType {
	id: number;
	code: string;
	name: string;
	isMultisolving?: boolean;
	isPriority?: boolean;
	incidentAvailability?: boolean;
	isCurrentService?: boolean;
	sent?: boolean;
	solvingDate?: Date;
}

export type IncidentDTO = {
	trainId: number;
	incidentTypeId?: IncidentTypeEnum;
	createdLineId: string;
} & Item;

export type RSIncident = {
	id: number | undefined;
	code: string;
	name: string;
	incidentTypeId: IncidentTypeEnum;
	complementId?: string | number;
	isMultisolving?: boolean;
	isPriority?: boolean;
	sent?: boolean;
	solvingDate?: Date;
};

export interface IncidentTypeRightsList {
	incidentTypesCreate: IncidentType[];
	incidentTypesExport: IncidentType[];
	incidentTypesDelete: IncidentType[];
	incidentTypesSolve: IncidentType[];
}

export type IncidentSummary = {
	trainId: number;
	count: number;
	incidentType: IncidentType;
	isPriority?: boolean;
};

export enum IncidentTypeEnum {
	GR = 0,
	DI = 1,
	PB = 2,
	OP = 3,
}

export const IncidentTypeMap: Map<
	IncidentTypeEnum | MultipleAvailabilityTypeEnum,
	string
> = new Map([
	[IncidentTypeEnum.PB, 'Pare-Brise'],
	[IncidentTypeEnum.GR, 'Graffiti'],
	[IncidentTypeEnum.DI, 'Signalement intérieur'],
	[IncidentTypeEnum.OP, 'Opération spéciale'],
]);

export const IncidentTypeCodeMap: Map<IncidentTypeEnum, string> = new Map([
	[IncidentTypeEnum.PB, 'PB'],
	[IncidentTypeEnum.GR, 'GR'],
	[IncidentTypeEnum.DI, 'DI'],
	[IncidentTypeEnum.OP, 'OP'],
]);
