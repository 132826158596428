import {
	Navigate,
	Route as RouteDom,
	Routes as Switch,
} from 'react-router-dom';

import {
	CodePage,
	ConfirmationPage,
	CredentialsPage,
	DashboardPage,
	HomePage,
	LandingPage,
	LogoutPage,
	NotFoundPage,
	QrScannerPage,
	RightPage,
	SelectionPage,
	SemSettingPage,
	SettingPage,
	SolvedIncidentReportPage,
	SpecialOperationPage,
	STTrackingBoardPage,
	SuccessPage,
	TrackingRsPage,
	usedRSPage,
} from '../pages';
import specialOperationRsPage from '../pages/desktop/specialOperationRsPage';

import { Endpoints as EP } from './endpoint.config';
import PrivateRoute from './privateRoute';

export const Routes = () => {
	return (
		<Switch>
			<RouteDom element={<HomePage />} path={EP.LOGIN} />
			<RouteDom element={<LogoutPage />} path={EP.LOGOUT} />
			<RouteDom
				element={<PrivateRoute Component={LandingPage} path={EP.AGENTN} />}
				path={EP.AGENTN}
			/>
			,
			<RouteDom
				element={
					<PrivateRoute Component={SelectionPage} path={EP.AGENTN_SELECTION} />
				}
				path={EP.AGENTN_SELECTION}
			/>
			,
			<RouteDom
				element={
					<PrivateRoute Component={QrScannerPage} path={EP.AGENTN_QR_SCANNER} />
				}
				path={EP.AGENTN_QR_SCANNER}
			/>
			,
			<RouteDom
				element={<PrivateRoute Component={CodePage} path={EP.AGENTN_BY_CODE} />}
				path={EP.AGENTN_BY_CODE}
			/>
			,
			<RouteDom
				element={
					<PrivateRoute Component={ConfirmationPage} path={EP.AGENTN_CONFIRM} />
				}
				path={EP.AGENTN_CONFIRM}
			/>
			,
			<RouteDom
				element={
					<PrivateRoute Component={SuccessPage} path={EP.AGENTN_SUCCESS} />
				}
				path={EP.AGENTN_SUCCESS}
			/>
			,
			<RouteDom
				element={<PrivateRoute Component={SpecialOperationPage} path={EP.OP} />}
				path={EP.OP}
			/>
			<RouteDom
				element={
					<PrivateRoute Component={specialOperationRsPage} path={EP.OPRS} />
				}
				path={EP.OPRS}
			/>
			,
			<RouteDom
				element={
					<PrivateRoute
						Component={TrackingRsPage}
						path={EP.TRACKING_BOARD_RS}
					/>
				}
				path={EP.TRACKING_BOARD_RS}
			/>
			,
			<RouteDom
				element={
					<PrivateRoute
						Component={STTrackingBoardPage}
						path={EP.TRACKING_BOARD_ST}
					/>
				}
				path={EP.TRACKING_BOARD_ST}
			/>
			,
			<RouteDom
				element={
					<PrivateRoute Component={CredentialsPage} path={EP.HABILITATION} />
				}
				path={EP.HABILITATION}
			/>
			,
			<RouteDom
				element={<PrivateRoute Component={DashboardPage} path={EP.REPORTING} />}
				path={EP.REPORTING}
			/>
			,
			<RouteDom
				element={
					<PrivateRoute
						Component={SolvedIncidentReportPage}
						path={EP.SOLVEDINCIDENTREPORT}
					/>
				}
				path={EP.SOLVEDINCIDENTREPORT}
			/>
			,
			<RouteDom
				element={<PrivateRoute Component={SettingPage} path={EP.SETTING} />}
				path={EP.SETTING}
			/>
			<RouteDom
				element={
					<PrivateRoute Component={SemSettingPage} path={EP.SEMSETTING} />
				}
				path={EP.SEMSETTING}
			/>
			,
			<RouteDom
				element={<PrivateRoute Component={RightPage} path={EP.RIGHT} />}
				path={EP.RIGHT}
			/>
			<RouteDom
				element={<PrivateRoute Component={usedRSPage} path={EP.USED_RS} />}
				path={EP.USED_RS}
			/>
			<RouteDom element={<NotFoundPage />} path="/404" />
			<RouteDom element={<Navigate to="/404" />} path="*" />
		</Switch>
	);
};

export default Routes;
