import { Department, DepartmentEnum, RoleEnum, User } from '../models';
import {
	hasDepartment,
	hasRole,
	LocalStorageEntries,
	localStorageFuncs,
} from '../packages/helpers';

import { Endpoints } from './endpoint.config';

const {
	AGENTN,
	AGENTM,
	PROPRETE,
	RESPROPRETE,
	TR,
	TRMAL,
	ADMIN,
	PROPRETESNCF,
} = RoleEnum;

function getDepartment(
	departments: Department[] | undefined
): DepartmentEnum | undefined {
	const { RER, MTS, SEM } = DepartmentEnum;
	if (departments === undefined) return undefined;
	const departmentIds = departments.map((dep) => dep.id);
	if (departmentIds.includes(RER)) return RER;
	else if (departmentIds.includes(MTS)) return MTS;
	else if (departmentIds.includes(SEM)) return SEM;
	else return undefined;
}

export const getRedirectPath = (user: User | undefined) => {
	if (!user || user.departments?.length === 0) return '/';

	const isAgentN = hasRole(user, [AGENTN]);
	const isRp = hasRole(user, [PROPRETE, RESPROPRETE]);
	const isAdmin = hasRole(user, [ADMIN]);
	const isAgentM = hasRole(user, [AGENTM]);
	const isRER = hasDepartment(user, DepartmentEnum.RER);
	const isSEM = hasDepartment(user, DepartmentEnum.SEM);

	if (isAdmin)
		return localStorageFuncs.get(
			`last_location_${getDepartment(user.departments)}_${
				user.roles[0].id
			}` as LocalStorageEntries
		) || isRER
			? Endpoints.TRACKING_BOARD_RS
			: Endpoints.REPORTING;

	if ((isRp || isAgentM) && isSEM) return Endpoints.TRACKING_BOARD_ST;

	if (isAgentN) return Endpoints.AGENTN;
	if (hasRole(user, [PROPRETE, RESPROPRETE, AGENTM, TR, TRMAL, PROPRETESNCF]))
		return (
			localStorageFuncs.get(
				`last_location_${getDepartment(user.departments)}_${
					user.roles[0].id
				}` as LocalStorageEntries
			) ?? Endpoints.TRACKING_BOARD_RS
		);

	return '/';
};
