export enum RoleEnum {
	ADMIN = 0,
	PROPRETE = 1,
	AGENTM = 2,
	AGENTN = 3,
	TR = 4,
	CSDD = 5,
	PRDD = 6,
	PROPRETESNCF = 7,
	TRMAL = 8,
	RESPROPRETE = 9,
	SMTRTEST = 999,
}

export interface Role {
	id: RoleEnum;
	code: string;
	name: string;
	checked?: boolean;
}
