import { isEmpty, isNil } from 'lodash';

import {
	IncidentReportSearch,
	IncidentReportSearchQuery,
	IncidentReportSearchResult,
	IncidentReportSearchResultDTO,
} from '../../models/incidentReportSearch.model';
import { dateTimeService } from '../../packages/helpers';
export const getEmptyIncidentReportSearch = (): IncidentReportSearch => ({
	creationEndDate: null,
	creationStartDate: null,
	id: undefined,
	incidentType: undefined,
	rollingstock: undefined,
	solvingEndDate: null,
	solvingStartDate: null,
});

export const getIncidentReportSearchQuery = (
	incidentReportSearch: IncidentReportSearch,
	lineId: string
): IncidentReportSearchQuery => {
	const {
		startDate: updatedCreationStartDate,
		endDate: updatedCreationEndDate,
	} = updateEndAndStartDate(
		incidentReportSearch.creationEndDate,
		incidentReportSearch.creationStartDate
	);
	const { startDate: updatedSolvingStartDate, endDate: updatedSolvingEndDate } =
		updateEndAndStartDate(
			incidentReportSearch.solvingEndDate,
			incidentReportSearch.solvingStartDate
		);
	return {
		creationEndDate: updatedCreationEndDate,
		creationStartDate: updatedCreationStartDate,
		id: isEmpty(incidentReportSearch.id) ? undefined : incidentReportSearch.id,
		incidentTypeId: incidentReportSearch.incidentType?.id,
		lineId,
		rollingstockId: incidentReportSearch.rollingstock?.id,
		solvingEndDate: updatedSolvingEndDate,
		solvingStartDate: updatedSolvingStartDate,
	};
};

export const dtoToSolvedIncidentReport = (
	dto: IncidentReportSearchResultDTO
): IncidentReportSearchResult => {
	return {
		creationDate: new Date(dto.creationDate),
		id: dto.id,
		incidentTypeId: dto.incidentTypeId,
		isSent: dto.isSent,
		solvingDate: new Date(dto.solvingDate),
		trainCode: dto.trainCode,
	};
};

export const updateEndAndStartDate = (
	endDate: Date | null,
	startDate: Date | null
) => {
	if (startDate && endDate) {
		return {
			endDate: new Date(endDate.setHours(23, 59, 59)),
			startDate: new Date(startDate.setHours(0, 0, 0)),
		};
	} else if (startDate && isNil(endDate)) {
		return {
			endDate: new Date(
				dateTimeService
					.addOrSubtractMonthsToDate(startDate, 3, 'addition')
					.setHours(23, 59, 59)
			),
			startDate: new Date(startDate.setHours(0, 0, 0)),
		};
	} else if (endDate && isNil(startDate)) {
		return {
			endDate: new Date(endDate.setHours(23, 59, 59)),
			startDate: new Date(
				dateTimeService.addOrSubtractMonthsToDate(endDate, 3).setHours(0, 0, 0)
			),
		};
	} else {
		return { endDate: null, startDate: null };
	}
};
