import { ThunkAction } from 'redux-thunk';

import { getFormattedIncidentReport } from '../../components/incidentReport/config';
import {
	ExceptionDTO,
	IncidentReport,
	IncidentType,
	PopupType,
	RSIncident,
} from '../../models';
import { SolvedIncidentReport } from '../../models/incidentSolving.model';
import IncidentReportProvider from '../../packages/dataProviders/resources/incidentReport';
import IncidentSolvingProvider from '../../packages/dataProviders/resources/incidentSolving';
import { NotifyCode } from '../../packages/helpers/popup/exception.config';
import {
	errorConf,
	exceptionDTOtoPopupState,
} from '../../packages/helpers/popup/exception.utils';
import {
	deleteRollingStockAvailabilityByRsId,
	deleteRollingStockIncident,
	setRollingStockIncident,
	updateRollingStockIncident,
} from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';
const { INCIDENTS } = components;

type IncidentReportThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;

const getFeedBackMessage = (incidentType: IncidentType) => {
	let message = NotifyCode.INF_FUNC_006 as string;
	message = message.replace('?', incidentType.name);

	return exceptionDTOtoPopupState(message, PopupType.SUCCESS, false, false);
};

export const createAndSetIncidentReport =
	(
		incidentReport: IncidentReport,
		incidentType: IncidentType | undefined
	): IncidentReportThunk =>
	async (dispatch, _, api) => {
		let res;
		try {
			dispatch(api._request(INCIDENTS));
			res = await IncidentReportProvider.createIncidentReport(
				await getFormattedIncidentReport(incidentReport)
			);

			if (res.ok) {
				dispatch(api._response(INCIDENTS));
				if (incidentType) {
					dispatch(
						setRollingStockIncident(
							{
								code: incidentType.code,
								complementId: incidentReport.complement?.id,
								id: res.data.id,
								incidentTypeId: incidentType.id,
								isPriority:
									incidentReport.complement?.id === 4 ||
									incidentReport.isOffensive,
								name: incidentType.name,
							},
							res.data.rollingStockId
						)
					);
					dispatch(api.showFeedBack(getFeedBackMessage(incidentType)));
				}
			}
		} catch (e) {
			dispatch(api._error(INCIDENTS, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};

export const deleteAndSetIncidentReport =
	(
		incidentReportId: number,
		rollingStockId: number,
		incidentName: string,
		trainCode: string | undefined
	): IncidentReportThunk =>
	async (dispatch, _, api) => {
		let res;
		try {
			dispatch(api._request(INCIDENTS));
			res = await IncidentReportProvider.deleteIncidentReport(incidentReportId);
			if (res.ok) {
				dispatch(api._response(INCIDENTS));
				dispatch(deleteRollingStockIncident(incidentReportId, rollingStockId));
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							`Anomalie supprimée, L'anomalie ${incidentName} sur l'élément ${trainCode} a bien été supprimée`,
							PopupType.SUCCESS,
							false,
							false
						)
					)
				);
			} else {
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							res.statusText as unknown as ExceptionDTO,
							PopupType.WARN
						)
					)
				);
			}
		} catch (err) {
			dispatch(api._error(INCIDENTS, err));
			dispatch(api.showFeedBack(errorConf.error['incidentReportNotDeleted']));
		}
	};

export const updateAndSetIncidentReport =
	(
		incidentReport: IncidentReport,
		incidentType: IncidentType | undefined,
		trainCode: string | undefined
	): IncidentReportThunk =>
	async (dispatch, _, api) => {
		let res;
		try {
			dispatch(api._request(INCIDENTS));
			res = await IncidentReportProvider.updateIncidentReport(
				await getFormattedIncidentReport(incidentReport)
			);

			if (res.ok) {
				dispatch(api._response(INCIDENTS));
				if (incidentType) {
					dispatch(
						updateRollingStockIncident(
							{
								code: incidentType.code,
								complementId: incidentReport.complement?.id,
								id: res.data.id,
								incidentTypeId: incidentType.id,
								isPriority:
									incidentReport.complement?.id === 4 ||
									incidentReport.isOffensive,
								name: incidentType.name,
								sent: res.data.sent,
								solvingDate: res.data.solvingDate,
							},
							incidentReport.rollingStockId
						)
					);
				}
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							`Anomalie modifiée, L'anomalie ${incidentType?.name} sur l'élément ${trainCode} a bien été modifiée`,
							PopupType.SUCCESS,
							false,
							false
						)
					)
				);
			} else {
				dispatch(api._error(INCIDENTS, res.statusText));
			}
		} catch (err) {
			dispatch(api._error(INCIDENTS, err));
			dispatch(api.showFeedBack(errorConf.error['incidentReportNotUpdated']));
		}
	};

export const resolveAndDeleteRollingStockIncident =
	(
		incidentReportsSelected: RSIncident[],
		incidentSelectedName: string,
		solvedIncidentReport: SolvedIncidentReport,
		trainId: number,
		npHighLimit: number
	): IncidentReportThunk =>
	async (dispatch, _, api) => {
		let res;
		try {
			dispatch(api._request(INCIDENTS));

			res = await IncidentSolvingProvider.solveIncidentReport(
				solvedIncidentReport
			);
			if (res.ok) {
				dispatch(api._response(INCIDENTS));
				incidentReportsSelected.forEach((incidentReport) => {
					if (incidentReport.id) {
						const { code, id } = incidentReport;
						dispatch(deleteRollingStockAvailabilityByRsId(trainId, code));
						dispatch(deleteRollingStockIncident(id, trainId, npHighLimit));
					}
				});
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							`Anomalie ${incidentSelectedName} a bien été marquée comme résolue`,
							PopupType.SUCCESS,
							false,
							false
						)
					)
				);
			} else {
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							res.statusText as unknown as ExceptionDTO,
							PopupType.WARN
						)
					)
				);
			}
		} catch (err) {
			dispatch(api._error(INCIDENTS, err));
			dispatch(api.showFeedBack(errorConf.error['incidentReportNotResolved']));
		}
	};

export const resolvePartiallyAndUpdateRollingStockIncident =
	(
		selectedIncidents: RSIncident[],
		rollingStockId: number,
		solvedIncidentReport: SolvedIncidentReport
	): IncidentReportThunk =>
	async (dispatch, _, api) => {
		let res;
		try {
			dispatch(api._request(INCIDENTS));

			res = await IncidentSolvingProvider.solveIncidentReport(
				solvedIncidentReport
			);
			if (res.ok) {
				dispatch(api._response(INCIDENTS));
				selectedIncidents.forEach((incident) => {
					dispatch(
						deleteRollingStockAvailabilityByRsId(rollingStockId, 'GR', 0)
					);
					dispatch(updateRollingStockIncident({ ...incident }, rollingStockId));
				});
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							`Anomalie graffiti a bien été signalée comme résolue partiellement`,
							PopupType.WARN,
							false,
							false
						)
					)
				);
			} else {
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							res.statusText as unknown as ExceptionDTO,
							PopupType.WARN
						)
					)
				);
			}
		} catch (err) {
			dispatch(api._error(INCIDENTS, err));
			dispatch(api.showFeedBack(errorConf.error['incidentReportNotResolved']));
		}
	};
