/* eslint-disable no-mixed-spaces-and-tabs */
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { isNil } from 'lodash';

import {
	IncidentRailcarDTO,
	IncidentReport,
	IncidentType,
} from '../../../../../models';
import { EDIT_RESOLVED_INCIDENT } from '../../../../../packages/helpers/rigths';
import { useIncidentType, useRight } from '../../../../../packages/hooks';
import { IconButton } from '../../../../../packages/ui';
import { DeleteIcon, EditIcon } from '../../../../../packages/ui/icons';
import IncidentReportFormContent from '../../IncidentReportFormContent';

const FormContentDetails = ({
	errors,
	files,
	handleChange,
	handleCheck,
	handleCheckOneOf,
	handleCheckChange,
	handleDateChange,
	handleElementSelectOneOf,
	handleFiles,
	handleDeleteFile,
	handleSelect,
	incidentIndex,
	incidentReport,
	incidentReport: { id: incidentReportId },
	incidentType,
	isDeletingMode,
	isIncidentSolved,
	selectedIncidentReportId,
	setIncidentNumber,
	setIsDeletingMode,
	setSelectedIncidentReportId,
	setValues,
	values,
}: FormContentDetailsProps) => {
	const { can } = useRight();
	const { canEditIncidentReport, canDeleteIncidentReport } = useIncidentType();
	const { id: incidentTypeId, name: incidentTypeName } = incidentType;
	const isSelected =
		!isNil(selectedIncidentReportId) &&
		selectedIncidentReportId === incidentReportId;

	const handleEditClick = () => {
		if (incidentReportId) setSelectedIncidentReportId(incidentReportId);
		setIncidentNumber(incidentIndex);
	};

	const handleDeleteClick = () => {
		if (incidentReportId) {
			setSelectedIncidentReportId(incidentReportId);
			setIsDeletingMode(true);
			setIncidentNumber(incidentIndex);
		}
	};

	useEffect(() => {
		if (isSelected) setValues(incidentReport);
	}, [selectedIncidentReportId]);

	return (
		<>
			<Grid container alignItems={'center'}>
				<Grid item xs={8}>
					{!isIncidentSolved && (
						<Typography variant="h3" paddingLeft={2}>
							{incidentTypeName} {incidentIndex}
						</Typography>
					)}
					{isIncidentSolved && (
						<Typography variant="h3" paddingLeft={2}>
							{incidentTypeName}
						</Typography>
					)}
				</Grid>

				<Grid item xs={4} paddingRight={2}>
					<Grid container justifyContent="flex-end">
						{canEditIncidentReport(incidentTypeId) &&
						!incidentReport.sent &&
						!isIncidentSolved ? (
							<IconButton
								onClick={selectedIncidentReportId ? undefined : handleEditClick}
								bgcolor={selectedIncidentReportId ? 'secondary' : 'primary'}
							>
								<EditIcon />
								<Typography paddingLeft={1} variant="h6">
									Modifier
								</Typography>
							</IconButton>
						) : can(EDIT_RESOLVED_INCIDENT) &&
						  !incidentReport.sent &&
						  isIncidentSolved ? (
							<IconButton
								onClick={selectedIncidentReportId ? undefined : handleEditClick}
								bgcolor={selectedIncidentReportId ? 'secondary' : 'primary'}
							>
								<EditIcon />
								<Typography paddingLeft={1} variant="h6">
									Modifier
								</Typography>
							</IconButton>
						) : (
							''
						)}
						{canDeleteIncidentReport(incidentTypeId) &&
							!incidentReport.sent &&
							!isIncidentSolved && (
								<IconButton
									onClick={
										selectedIncidentReportId ? undefined : handleDeleteClick
									}
									bgcolor={selectedIncidentReportId ? 'secondary' : 'error'}
								>
									<DeleteIcon />
									<Typography paddingLeft={1} variant="h6">
										Supprimer
									</Typography>
								</IconButton>
							)}
					</Grid>
				</Grid>
			</Grid>
			<IncidentReportFormContent
				errors={errors}
				files={files}
				handleChange={handleChange}
				handleCheck={handleCheck}
				handleCheckChange={handleCheckChange}
				handleCheckOneOf={handleCheckOneOf}
				handleDateChange={handleDateChange}
				handleDeleteFile={handleDeleteFile}
				handleElementSelectOneOf={handleElementSelectOneOf}
				handleFiles={handleFiles}
				handleSelect={handleSelect}
				incidentReport={
					isSelected ? (values as IncidentReport) : incidentReport
				}
				isEditableForm={isSelected && !isDeletingMode}
				isIncidentDetails={true}
				isIncidentSolved={isIncidentSolved}
				setValues={setValues}
			/>
		</>
	);
};

export default FormContentDetails;

type FormContentDetailsProps = {
	incidentReport: IncidentReport;
	incidentType: IncidentType;
	incidentIndex: number;
	isDeletingMode: boolean;
	isIncidentSolved: boolean;
	setIncidentNumber: (value: number) => void;
	setIsDeletingMode: Dispatch<SetStateAction<boolean>>;
	setSelectedIncidentReportId: Dispatch<SetStateAction<number | null>>;
	selectedIncidentReportId: number | null;
	errors: { [key: string]: string };
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleCheckOneOf: (
		event: React.ChangeEvent<HTMLInputElement>,
		of: string
	) => void;
	handleDateChange: any;
	handleElementSelectOneOf: (element: IncidentRailcarDTO, of: string) => void;
	handleSelect: (event: SelectChangeEvent) => void;
	files?: any;
	handleFiles?: any;
	values: any;
	setValues: Dispatch<SetStateAction<any>>;
	handleDeleteFile: (event: any, filename: string, isFile?: boolean) => void;
};
